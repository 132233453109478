.ui.menu.toolbar_navigation {
  display: flex;
  align-items: center;
}

img.ui.circular.image.toolbar_logo {
  margin-left: 1.5rem;
}

@media (min-width: 768px) {
  .toggleButton {
    display: none;
  }
}

@media (max-width: 768px) {
  .toggleButton {
    padding: 1rem;
  }
  .ui.labeled.icon.menu .item.main {
    display: none;
  }
  h1.ui.header {
    margin-left: auto;
    margin-right: 1rem;
  }
  h1.ui.header span {
    display: none;
  }
}
