* {
  padding: 0;
  margin: 0;
}

html {
  height: 100% !important;
}

body {
  margin: 0;
  height: 100% !important;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ui.menu.toolbar_navigation {
  display: flex;
  align-items: center;
}

img.ui.circular.image.toolbar_logo {
  margin-left: 1.5rem;
}

@media (min-width: 768px) {
  .toggleButton {
    display: none;
  }
}

@media (max-width: 768px) {
  .toggleButton {
    padding: 1rem;
  }
  .ui.labeled.icon.menu .item.main {
    display: none;
  }
  h1.ui.header {
    margin-left: auto;
    margin-right: 1rem;
  }
  h1.ui.header span {
    display: none;
  }
}

.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
}

.toggle-button:focus {
    outline: none;
}

.toggle-button_line {
    width: 30px;
    height: 2px;
    background: black;
}
@media (max-width: 1038px) {
    .carousel.slide {
        height: 100%;
    }
    .carousel.img{
        height: 100% !important;
    }
}
